<template>
    <div class="list">
        <listing-actions
            v-if="showActions"
            :not-exist-label="$t('page.myReviews.noResult')"
            :page="ordersData.page"
        />
        <div
            v-if="existData"
            class="items"
            :class="{ empty: noItems }"
        >
            <div
                v-for="order in ordersData.elements"
                :key="`order-${order.id}`"
                class="item d-flex sb"
            >
                <div class="details">
                    <div class="heading d-flex">
                        <p class="sbold">
                            {{ $t('page.ordersHistory.listing.orderNumberText') }}
                            {{ order.id }}
                        </p>
                        <div
                            v-if="!isLowScreen"
                            class="separator"
                        >
                            <p>-</p>
                        </div>
                        <span :class="order.status.value">{{ order.status.label }}</span>
                    </div>
                    <div class="info d-flex">
                        <p class="sm-sbold">
                            {{ $t('page.ordersHistory.listing.placedOnText') }}
                            {{ order.order_placed_date }}
                        </p>
                        <div
                            v-if="!isLowScreen"
                            class="separator"
                        >
                            <p>|</p>
                        </div>
                        <p
                            v-dompurify-html="formatPrice(order.total)"
                            class="has-sup sm-sbold"
                        />
                    </div>
                    <div class="images d-flex">
                        <router-link
                            v-for="product in order.products"
                            :key="`product-img-${order.products.indexOf(product)}`"
                            :to="{ name: 'Produs', params: { slug: product.slug } }"
                            :title="product.name"
                        >
                            <img
                                :src="product.thumbnail_url"
                                width="60"
                                height="40"
                                :alt="product.name"
                            >
                        </router-link>
                        <div
                            v-for="voucher in order.vouchers"
                            :key="`voucher-img-${order.vouchers.indexOf(voucher)}`"
                            :title="voucher.name"
                        >
                            <img
                                :src="voucher.thumbnail_url"
                                width="60"
                                height="40"
                                :alt="voucher.name"
                            >
                        </div>
                    </div>
                </div>
                <div class="action">
                    <router-link
                        :to="{ name: 'DetaliiComanda', params: { id: order.id } }"
                        :class="isLowScreen ? '' : 'btn btn-primary stroke sm'"
                    >
                        <eye-icon :class="isLowScreen ? 'sm primary' : ''" />{{
                            buttonLabel
                        }}
                    </router-link>
                </div>
            </div>
        </div>
        <listing-pagination
            v-if="existData"
            :page="ordersData.page"
            type="order"
        />
    </div>
</template>

<script>
    import EyeIcon from 'vue-feather-icons/icons/EyeIcon';
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    import ListingPagination from '../category/ListingPagination';
    import ListingActions from '../orderHistory/ListingActions';
    export default {
        name: 'OrderHistoryListing',
        components: {
            EyeIcon,
            ListingActions,
            ListingPagination,
        },
        data() {
            return {
                screenWidth: 0,
            };
        },
        computed: {
            ...mapState('order', ['ordersData']),
            noItems() {
                return this.ordersData.page.total == 0;
            },
            isLowScreen() {
                return this.screenWidth < 576;
            },
            buttonLabel() {
                return !this.isLowScreen
                    ? this.$t('page.ordersHistory.listing.seeDetailsButton')
                    : '';
            },
            existData(){
                return this.ordersData.elements;
            },
            showActions() {
                return (
                    !this.loading ||
                    (this.loading && this.existData)
                );
            },
        },
        methods: {
            formatPrice(price) {
                return this.$t('page.ordersHistory.details.totalText', { number: splitedPrice(price) });
            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
            this.screenWidth = window.innerWidth;
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
    };
</script>
<style scoped lang="scss">
.items {
  min-height: 350px;
  @include media-breakpoint-up(sm) {
    padding-top: 24px;
    &:not(.empty) {
      border-top: 1px solid $gray-light;
    }
  }
  .item {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray-thin;
    &:last-of-type {
      @include media-breakpoint-up(sm) {
        border: none;
      }
    }
    .details {
      .heading {
        align-items: center;
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: initial;
          margin-bottom: 16px;
        }
        p {
          color: $text;
        }
        .separator {
          color: $gray;
        }
        span {
          text-transform: uppercase;
          width: fit-content;
          border: 1px solid $secondary;
          color: $secondary;
          font-size: 10px;
          padding: 2px 8px 0px 8px;
          border-radius: 4px;
          letter-spacing: 1px;
          line-height: 16px;
          font-weight: 600;
          text-align: center;
          &.cancelled {
            border-color: $red-light;
            color: $red-light;
          }
          &.completed {
            border-color: $primary-75;
            color: $primary-75;
          }
        }
      }

      .info {
        margin-top: 4px;
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: initial;
          gap: 0;
        }
        p {
          color: $gray-dark;
        }
      }
      .images {
        max-width: 400px;
        margin-top: 8px;
        gap: 8px;
        flex-wrap: wrap;
        a,
        div {
          background-color: $gray-thin;
          padding: 2px;
          img {
            object-fit: contain;
          }
          @include media-breakpoint-down(sm) {
            flex: 0 0 calc(25% - 6px);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .action {
      .btn {
        margin: 0;
      }
      @include media-breakpoint-down(sm) {
        position: absolute;
        right: 16px;
        top: 16px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 16px;
      border: 1px solid $gray-light;
      box-shadow: inset 4px 0px 0px $gray-light;
      border-radius: 4px;
    }
  }
}
</style>
