<template>
    <div class="container">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <b-row>
            <sidebar />
            <b-col
                cols="12"
                md="9"
                class="orders mtd-40"
                :class="{ transparent: showLoader }"
            >
                <h4>{{ $t('page.ordersHistory.listing.heading') }}</h4>
                <order-history-listing v-if="existData" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import Sidebar from '@/components/page/Sidebar';
    import { splitedPrice } from '@/services/getSplitedPrice';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import OrderHistoryListing from './orderHistory/OrderHistoryListing';

    export default {
        name: 'OrderHistory',
        components: {
            Sidebar,
            OrderHistoryListing,
            LoadingSpinner,
        },
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapState('order', ['ordersData']),
            lang() {
                return i18n.locale;
            },
            existData() {
                return Object.keys(this.ordersData).length > 0;
            },
            showLoader() {
                return !this.existData && this.loading;
            },
        },
        methods: {
            ...mapActions('order', ['getOrderHistory']),
            async loadData() {
                this.loading = true;
                await this.getOrderHistory();
                this.loading = false;
            },
            formatPrice(price) {
                return splitedPrice(price);
            },
        },
        created() {
            this.loadData();
        },
        watch: {
            lang: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.ordersTitle'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
