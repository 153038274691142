<template>
    <b-row
        v-if="loaded"
        class="listing-actions mb-3"
    >
        <b-col
            cols="12"
            lg="6"
            md="12"
            class="d-flex mt-4"
        >
            <p class="sm-sbold mr-8">
                {{ totalText }}
            </p>
            <div
                v-if="!noItems"
                class="separator"
            />
            <listing-per-page
                v-if="!noItems"
                :per-page="page.perPage"
            />
        </b-col>
    </b-row>
</template>

<script>
    import ListingPerPage from '@/pages/orderHistory/ListingPerPage';
    export default {
        name: 'ListingActions',
        components: { ListingPerPage },
        props: {
            page: {
                type: Object,
                required: true,
            },
        },
        computed: {
            totalText() {
                if (this.page.total != 0) {
                    return this.page.total > 1
                        ? this.$t('page.ordersHistory.listing.resultMultiText', { number: this.page.total })
                        : this.$t('page.ordersHistory.listing.resultText', { number: this.page.total });
                }
                return this.$t('page.ordersHistory.listing.noResult');
            },
            noItems() {
                return this.page.total == 0;
            },
            loaded() {
                return Object.keys(this.page).length > 0;
            },
        },
    };
</script>
<style scoped lang="scss">
.listing-actions {
  div {
    align-items: center;
    @include media-breakpoint-down(xm) {
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      color: $text;
    }
    .separator {
      width: 1px;
      height: 16px;
      background-color: $gray-light;
      @include media-breakpoint-down(xm) {
        display: none;
      }
    }
  }
}
</style>
