<template>
    <span
        key="perPage"
        v-click-outside="closeDropdown"
        class="dropdown perPage here"
        :class="{ active: perPageIsActive }"
        @click="perPageIsActive = !perPageIsActive"
    >
        <p class="sm-sbold">{{ $t('page.ordersHistory.listing.perPageLabel') }}</p>
        <p class="sm-sbold">{{ query.value.perPage }} {{ $t('page.heading.perPageText') }}</p>
        <chevron-down-icon />
        <ul>
            <li
                v-for="pageNumber in pageNumbers"
                :key="`page-${pageNumber}`"
                :class="{ active: isActive(pageNumber) }"
                @click="change(pageNumber)"
            >
                <p class="sm-sbold">
                    {{ pageNumber }} {{ $t('page.heading.perPageText') }}
                </p>
            </li>
        </ul>
    </span>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import { mapActions, mapMutations } from 'vuex';

    export default {
        name: 'ListingPerPage',
        components: {
            ChevronDownIcon,
        },
        props: {
            perPage: {
                type: Number,
                default: 24,
            },
        },
        data() {
            return {
                perPageIsActive: false,
                query: {
                    value: {
                        perPage: 6,
                    },
                },
                pageNumbers: [6, 12, 24],
            };
        },
        methods: {
            ...mapActions('order', ['getOrderHistory']),
            ...mapMutations('order', ['setPage', 'setPerPage']),
            closeDropdown() {
                this.perPageIsActive = false;
            },
            change(perPage) {
                if (this.query.value.perPage != perPage) {
                    this.query.value.perPage = perPage;
                    this.setPage(1);
                    this.setPerPage(perPage);
                    this.getOrderHistory();
                }
            },
            isActive(perPage) {
                return perPage == this.query.value.perPage;
            },
            updatePerPage() {
                this.query.value.perPage = this.perPage;
            },
        },
        directives: {
            ClickOutside,
        },
        mounted() {
            this.updatePerPage();
        },
        watch: {
            perPage() {
                this.updatePerPage();
            },
        },
    };
</script>
<style scoped lang="scss">
.dropdown {
  @include media-breakpoint-down(xm) {
    width: fit-content;
    padding: 0;
  }
}
</style>
